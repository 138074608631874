// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    height: -moz-fit-content;
    height: fit-content;
}

.footer-back {
    position: relative;
    display: flex;
    height: 0px;
}

.footer-img {
    width: 100%;
    bottom: 0px;
    position: relative;
    height: 50px;
}

.footer-parent{
    display: flex;
    flex-direction: row;
}
.footer-content{
    width: 50%;
    display: flex;
    z-index: 2;
    position: relative;
    justify-content: right;
}

.footer-links{
    width: 50%;
    display: flex;
    z-index: 2;
    position: relative;
    justify-content: left;
}

.footer-links a{
    padding-top: 12px;
    padding-left: 50px;
    font-size: 20px;
    color: white;
    font-weight: bold;
    text-decoration:underline;
}

.footer-content span{
    padding-top: 12px;
    padding-right: 50px;
    font-size: 20px;
    font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/footer.css"],"names":[],"mappings":"AAAA;IACI,wBAAmB;IAAnB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,WAAW;AACf;;AAEA;IACI,WAAW;IACX,WAAW;IACX,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,UAAU;IACV,aAAa;IACb,UAAU;IACV,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,UAAU;IACV,aAAa;IACb,UAAU;IACV,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,YAAY;IACZ,iBAAiB;IACjB,yBAAyB;AAC7B;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,eAAe;IACf,iBAAiB;AACrB","sourcesContent":[".footer {\n    height: fit-content;\n}\n\n.footer-back {\n    position: relative;\n    display: flex;\n    height: 0px;\n}\n\n.footer-img {\n    width: 100%;\n    bottom: 0px;\n    position: relative;\n    height: 50px;\n}\n\n.footer-parent{\n    display: flex;\n    flex-direction: row;\n}\n.footer-content{\n    width: 50%;\n    display: flex;\n    z-index: 2;\n    position: relative;\n    justify-content: right;\n}\n\n.footer-links{\n    width: 50%;\n    display: flex;\n    z-index: 2;\n    position: relative;\n    justify-content: left;\n}\n\n.footer-links a{\n    padding-top: 12px;\n    padding-left: 50px;\n    font-size: 20px;\n    color: white;\n    font-weight: bold;\n    text-decoration:underline;\n}\n\n.footer-content span{\n    padding-top: 12px;\n    padding-right: 50px;\n    font-size: 20px;\n    font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
