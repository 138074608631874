import React from 'react';
import IteractiveSentence from "../../../iteractiveSentence.jsx";
import "./welcomeSection.css";
import { useTranslation } from 'react-i18next';

function WelcomeSection() {
    // const title = "Hi, I`m João."
    // const subtitle = "A Software Developer from Porto, Portugal eaguer to do more and better everyday." 

    const { t } = useTranslation(); 

    return (
        <div className='welcome-title'>
            <IteractiveSentence sentence={t('welcome.greeting')} cssName={"mainText"} initialColor={"#E407E8"} hoverColor={"#FFC0CB"} increaseOnHover={true} fontSize={"100px"}/>
            <IteractiveSentence sentence={t('welcome.description')} cssName={"secondaryText"} initialColor={"#2EE9FF"} hoverColor={"#05A0B3"} increaseOnHover={false} fontSize={"35px"}/>
        </div>
    );
}

export default WelcomeSection;
