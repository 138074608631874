// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.letstalk-background {
    height: 0px;
}

.letstalk-background img {
    position: relative;
    z-index: -1;
    width: 125%;
    left: -12.5%;
}

.lets-section {
    margin-top: 300px;
}

.lets-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 55px;
    font-weight: bold;
}

.content-subtitle {
    font-size: 20px;
}

.content-title {
    font-size: 95px;
    margin-top: -15px;
}

.content-button {
    cursor: pointer;
    height: 150px;
    width: 475px;
    font-size: 55px;
    font-weight: bold;
    border-style: none;
    margin: 4px 2px;
    color: white;
    text-align: center;
    text-decoration: none;
    background-color: black;
    padding: 0px;
    border-radius: 0px;
    padding: 15px 0px;
    display: inline-block;
    outline: none;
    margin-top: 115px;
    border-radius: 75px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Section/Sections/LetsTalk/letsTalk.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,aAAa;IACb,YAAY;IACZ,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,qBAAqB;IACrB,uBAAuB;IACvB,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,qBAAqB;IACrB,aAAa;IACb,iBAAiB;IACjB,mBAAmB;AACvB","sourcesContent":[".letstalk-background {\n    height: 0px;\n}\n\n.letstalk-background img {\n    position: relative;\n    z-index: -1;\n    width: 125%;\n    left: -12.5%;\n}\n\n.lets-section {\n    margin-top: 300px;\n}\n\n.lets-content {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    padding-top: 55px;\n    font-weight: bold;\n}\n\n.content-subtitle {\n    font-size: 20px;\n}\n\n.content-title {\n    font-size: 95px;\n    margin-top: -15px;\n}\n\n.content-button {\n    cursor: pointer;\n    height: 150px;\n    width: 475px;\n    font-size: 55px;\n    font-weight: bold;\n    border-style: none;\n    margin: 4px 2px;\n    color: white;\n    text-align: center;\n    text-decoration: none;\n    background-color: black;\n    padding: 0px;\n    border-radius: 0px;\n    padding: 15px 0px;\n    display: inline-block;\n    outline: none;\n    margin-top: 115px;\n    border-radius: 75px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
