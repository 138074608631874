import { React, useState } from "react";
import "./personalProjects.css";
import { useTranslation } from 'react-i18next';
import ProjectsPopup from "./ProjectsPopup/projectsPopup";

function PersonalProjects() {

  const Projects = {
    ShoppingDead: 'shopping_dead',
    SteamTopia: 'steamtopia',
  }
  const [currentProject, setCurrentProject] = useState(Projects.ShoppingDead);

  const [popupOpen, setPopupOpen] = useState(false);

  const changeCurrenProject = (project) => {
    setCurrentProject(project);
  }

  const openPopup = (project) => {
    setPopupOpen(true);
    setCurrentProject(project);
  }

  const closePopup = () => {
    setPopupOpen(false);
  }

  const { t } = useTranslation();
  return (
    <div className="personalArea">
      <div className="personalTitle">
        <span className="section-title">{t("personalproj.title")} </span>
      </div>
      <div className="personalContentArea">
        <div className="personalContent">
          <div className="projectArray">
            <div className="project" onClick={() => openPopup(Projects.ShoppingDead)}>
              <div className="projectImgContainer">
                <img
                  className="projectImg-shopdead"
                  src="assets/PersonalProj/ShoppingDead.png"
                  alt=""
                />
              </div>
              <div className="projectButton">
                <span className="projectTitle">The Shopping Dead</span>
              </div>
            </div>
            <div className="project" onClick={() => openPopup(Projects.SteamTopia)}>
              <div className="projectImgContainer">
                <img
                  className="projectImg-steamtopia"
                  src="assets/PersonalProj/SteamTopia.jpg"
                  alt=""
                />
              </div>
              <div className="projectButton">
                <span className="projectTitle">SteamTopia</span>
              </div>
            </div>
            <div className="project project-wip">
              <div className="projectImgContainer">
                <img
                  className="projectImg-scraper"
                  src="assets/PersonalProj/scrapper.png"
                  alt=""
                />
              </div>
              <div className="projectWIP">
                <span className="projectTitle">WIP (IN PROGRESS) </span>
              </div>
              <div className="projectButton">
                <span className="projectTitle">Value Scraper </span>
              </div>
            </div>
          </div>
        </div>
        {popupOpen && <ProjectsPopup currentProject={currentProject} setCurrentProject={changeCurrenProject} closePopup={closePopup}/>}
      </div>
    </div>
  );
}

export default PersonalProjects;
