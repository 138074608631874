import "./App.css";
import Background from "./components/background";
import Section from "./components/Section/section";
import TopNavBar from "./components/NavBars/TopNavBar/TopNavBar";   
import LeftNavBar from "./components/NavBars/LeftNavBar/LeftNavBar";
import React, {useState, useCallback, useEffect} from "react";
import { I18nextProvider } from 'react-i18next';
import i18n from './translations/i18n.js';
import Footer from "./components/Footer/footer.jsx";
import {  useLocation, useNavigate  } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


function App() {
  const [topNavBarHeight, setTopNavBarHeight] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();

  const {t} = useTranslation();

  useEffect(() => {
    const handleThankYouPage = () => {
      if (location.pathname === '/thank-you') {
        navigate('/');
        alert(t('contacts.popup.successalert'));
      }
    };

    // Call the function when the component mounts
    handleThankYouPage();

    // Listen to location changes and call handleThankYouPage when necessary
  },[location.pathname, navigate, t]);  

  const handleTopNavBarHeightChange = (height) => {
    setTopNavBarHeight(height);
  };


  const [section, setSection] = useState(0);

  const sectionHandler = useCallback((sectionIndex) => {
     setSection(sectionIndex);
    },
    []
  );
  const [popupOpen, setPopupOpen] = useState(false);

  const setContactsPopup = (open) =>{
    console.log("setContactsPopup", open);
    setPopupOpen(open);
  }

  return (
      <div className="App">
        <div className="App-header">
          <Background className="background" isocahedron={false} />
          <Background className="background-fixed" isocahedron={true } />
        </div>
        <I18nextProvider i18n={i18n}> 
        <div className="top-area nav-bar-top">
            <TopNavBar onClickContacts={setContactsPopup} onHeightChange={handleTopNavBarHeightChange}/>
          </div>

        <div className="main-container">
          <div className="split-side">
            <LeftNavBar topNavBarHeight={topNavBarHeight} section={section} />
          </div>

          <div className="division-center split-center">
            <Section onSectionViewPortChange={sectionHandler} onClickContacts={setContactsPopup} contactsPopupOpen={popupOpen}/>
          </div>

          <div className="split-side">
          </div>
        </div>

        <div className="bottom ">
          <Footer/>
        </div>
        </I18nextProvider>
      </div>
  );
}
export default App;
