import React, { useEffect, useState, useRef } from "react";
import "./sectionsNavbar.css";
import { useTranslation } from "react-i18next";

function LeftNavBar({ topNavBarHeight, section }) {

  const {t} = useTranslation();

  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const sectionsBar = useRef(null);

  const [selectedSection, setSelectedSection] = useState("section-welcome");

  const handleSpanClick = (sectionId) => {
    setSelectedSection(sectionId);
    var sections = document.querySelectorAll(".section");
    switch (sectionId) {
      case "section-welcome":
        sections[0].scrollIntoView({ behavior: "smooth" });
        break;
      case "section-aboutme":
        sections[1].scrollIntoView({ behavior: "smooth" });
        break;
      case "section-personal":
        sections[2].scrollIntoView({ behavior: "smooth" });
        break;
      case "section-skills":
        sections[3].scrollIntoView({ behavior: "smooth" });
        break;
      case "section-contact":
        sections[4].scrollIntoView({ behavior: "smooth" });
        break;
      default:
        sections[0].scrollIntoView({ behavior: "smooth" });
        break;
    }
  };

  const { i18n } = useTranslation();
  const [navbarHeight, setNavbarHeight] = useState(465);

  useEffect(() => {
    const handleLanguageChange = () => {
      setNavbarHeight(i18n.language === 'pt' ? 550 : 465);
    };

    i18n.on('languageChanged', handleLanguageChange);

    setNavbarHeight(i18n.language === 'pt' ? 550 : 465);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n]);

  const translateYScroll = Math.min(scrollPosition / 2, window.innerHeight / 8);

  useEffect(() => {
    console.log("Section index" +  section);
    switch (parseInt(section)) {
      case 0:
        setSelectedSection("section-welcome");
        break;
      case 1:
        setSelectedSection("section-aboutme");
        break;
      case 2:
        setSelectedSection("section-personal");
        break;
      case 3:
        setSelectedSection("section-skills");
        break;
      case 4:
        setSelectedSection("section-contact");
        break;
      default:
        setSelectedSection("section-welcome");
        break;
    }
  }, [section]);
  
  return (
    <div>
      <img
        ref={sectionsBar}
        className="nav-bar-section" 
        src="assets/NavLeftPink3.png"
        alt="section nav bar"
        style={{ transform: `translateY(${translateYScroll}px)`, minHeight: `${navbarHeight}px` }}
      />

      <div
        className="nav-bar-section"
        style={{
          marginTop: topNavBarHeight,
          transform: `translateY(${translateYScroll}px)`,
          minHeight: `${navbarHeight}px`,
        }}
      >
        <div className="section-content light-pink">
          <div
            id="section-welcome"
            className={`section-option ${
              selectedSection === "section-welcome" ? "selected" : ""
            }`}
            onClick={() => handleSpanClick("section-welcome")}
          >
            <div className="medium-bar" />
            <span>{t('welcome.title')}</span>
            <div className="small-bar" />
          </div>
          <div
            id="section-aboutme"
            className={`section-option ${
              selectedSection === "section-aboutme" ? "selected" : ""
            }`}
            onClick={() => handleSpanClick("section-aboutme")}
          >
            <div className="medium-bar" />
            <span>{t('aboutme.title')}</span>
            <div className="small-bar" />
          </div>
          <div
            id="section-personal"
            className={`section-option ${
              selectedSection === "section-personal" ? "selected" : ""
            }`}
            onClick={() => handleSpanClick("section-personal")}
          >
            <div className="medium-bar" />
            <span>{t('personalproj.title')}</span>
            <div className="small-bar" />
          </div>
          <div
            id="section-skills"
            className={`section-option ${
              selectedSection === "section-skills" ? "selected" : ""
            }`}
            onClick={() => handleSpanClick("section-skills")}
          >
            <div className="medium-bar" />
            <span>{t('skills.title')}</span>
            <div className="small-bar" />
          </div>
          <div
            id="section-contact"
            className={`section-option ${
              selectedSection === "section-contact" ? "selected" : ""
            }`}
            onClick={() => handleSpanClick("section-contact")}
          >
            <div className="medium-bar" />
            <span>{t('contacts.title')}</span>
            <div className="small-bar" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeftNavBar;
