// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` .nav-bar-background{
    height: auto;
    width: auto;
    float: left;
    max-width: 65%;
    max-height: 105px;
  }

  .menu-container{
    float: left;
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%); 
  }

  .menu-content {
    display: flex;
    align-items: center; 
    font-weight: bold;
    gap: 8vw;
    padding-left: 50px;
  }

  
  .section-container{
    float: left;
    position: relative;
  }

  .section-content {
    align-items: center;
    font-weight: bold;
    font-size: calc(.3vw + 0.83em);
    padding-left: 8px;
    padding-top: 15px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 8px;
    text-align: left;
}

  .menu-item{
    text-decoration: underline;
    text-decoration-thickness: 2px;
  }

  .menu-button{
    text-decoration-thickness: inherit !important;
  }`, "",{"version":3,"sources":["webpack://./src/components/NavBars/navBars.css"],"names":[],"mappings":"CAAC;IACG,YAAY;IACZ,WAAW;IACX,WAAW;IACX,cAAc;IACd,iBAAiB;EACnB;;EAEA;IACE,WAAW;IACX,kBAAkB;IAClB,QAAQ;IACR,8BAA8B;EAChC;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,QAAQ;IACR,kBAAkB;EACpB;;;EAGA;IACE,WAAW;IACX,kBAAkB;EACpB;;EAEA;IACE,mBAAmB;IACnB,iBAAiB;IACjB,8BAA8B;IAC9B,iBAAiB;IACjB,iBAAiB;IACjB,aAAa;IACb,4DAA4D;IAC5D,QAAQ;IACR,gBAAgB;AACpB;;EAEE;IACE,0BAA0B;IAC1B,8BAA8B;EAChC;;EAEA;IACE,6CAA6C;EAC/C","sourcesContent":[" .nav-bar-background{\n    height: auto;\n    width: auto;\n    float: left;\n    max-width: 65%;\n    max-height: 105px;\n  }\n\n  .menu-container{\n    float: left;\n    position: absolute;\n    top: 50%;\n    transform: translate(0%, -50%); \n  }\n\n  .menu-content {\n    display: flex;\n    align-items: center; \n    font-weight: bold;\n    gap: 8vw;\n    padding-left: 50px;\n  }\n\n  \n  .section-container{\n    float: left;\n    position: relative;\n  }\n\n  .section-content {\n    align-items: center;\n    font-weight: bold;\n    font-size: calc(.3vw + 0.83em);\n    padding-left: 8px;\n    padding-top: 15px;\n    display: grid;\n    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));\n    gap: 8px;\n    text-align: left;\n}\n\n  .menu-item{\n    text-decoration: underline;\n    text-decoration-thickness: 2px;\n  }\n\n  .menu-button{\n    text-decoration-thickness: inherit !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
