// import React, { Component } from 'react';
import AboutMeSection from "./Sections/AboutMe/aboutMeSection";
import WelcomeSection from "./Sections/Welcome/welcomeSection";
import PersonalProjects from "./Sections/PersonalProjects/personalProjects";
import "./section.css";
import MySkills from "./Sections/MySkills/mySkills";
import LetsTalk from "./Sections/LetsTalk/letsTalk";
import { useEffect, useCallback } from "react";

function Section({onSectionViewPortChange, onClickContacts, contactsPopupOpen}) {
  var sections = document.querySelectorAll(".section");

  for(var i = 0; i < sections.length; i++){
    sections[i].setAttribute("data-index", i);
  }

  const updateNavBar = useCallback(() => {
    var sectionsInViewPort = [];      
    sections.forEach(section => {
        if(section.getAttribute("in-viewport") === "true"){
            sectionsInViewPort.push(section);
        }
    });
    //get section with lower index from sectionsInViewPort
    var section = sectionsInViewPort.length > 0 ?
    sectionsInViewPort.reduce((prev, current) => 
        (prev.getAttribute("data-index") < current.getAttribute("data-index")) ? prev : current
    ) :
    null; 
    if(section !== null){
      onSectionViewPortChange(section.getAttribute("data-index"));
    }
}, [sections, onSectionViewPortChange]);


  useEffect(() => {
    var config = {
      rootMargin: "0px",
      threshold: 1
    };
    var observer = new IntersectionObserver(function (entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.setAttribute("in-viewport", "true");
        }else
        {
          entry.target.setAttribute("in-viewport", "false");
        }
        updateNavBar();
      });
    }, config);

    sections.forEach(section => {
      observer.observe(section);
    });

    return () => {
      observer.disconnect();
    };
  }, [sections, updateNavBar]);




  return (
    <div className="container">
      <div className="middle-left"/>
      <div className="middle-main">
        <div id="welcomeSection" className="section">
        <WelcomeSection/>
        </div>
        <div id="aboutMeSection" className="section">
        <AboutMeSection/>
        </div>
        <div id="personalProjectsSection" className="section">
          <PersonalProjects/>
          </div>
          <div id="mySkillsSection" className="section">
          <MySkills/>
          </div>
          <div id="letsTalkSection" className="section">
          <LetsTalk onClickContacts={onClickContacts} contactsPopupOpen={contactsPopupOpen}/>
          </div>
      </div>
      <div className="middle-right"/>
    </div>
  );
}

export default Section;
