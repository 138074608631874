// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.medium-bar {
    margin-bottom: 3px;
    width: 75%;
    height: 6px;
    background-color: #FFC0CB;
}

.section-option.selected .medium-bar {
    width: 95%;
}

.small-bar {
    width: 50%;
    height: 6px;
    background-color: #FFC0CB;
    margin-top: 5px;
}

.section-option.selected .small-bar{
    width: 70%;
} 

.small-bar.selected {
    width: 70%;
}

.section-option{
    opacity: 0.8;
}

.section-option.selected{
    opacity: 1;
}

.section-option.selected span {
    font-size: calc(.20vw + 0.83em);
}
.section-option{
    cursor: pointer; 
}

.section-option:not(.selected):hover {
    opacity: 0.9;
    transform: scale(1.03);
    transform-origin: left center;
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  
@media (max-width: 1500px) {
    .nav-bar-section {
        display: none;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/NavBars/LeftNavBar/sectionsNavbar.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,UAAU;IACV,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;IACV,WAAW;IACX,yBAAyB;IACzB,eAAe;AACnB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,+BAA+B;AACnC;AACA;IACI,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,sBAAsB;IACtB,6BAA6B;IAC7B,kDAAkD;EACpD;;AAEF;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":[".medium-bar {\n    margin-bottom: 3px;\n    width: 75%;\n    height: 6px;\n    background-color: #FFC0CB;\n}\n\n.section-option.selected .medium-bar {\n    width: 95%;\n}\n\n.small-bar {\n    width: 50%;\n    height: 6px;\n    background-color: #FFC0CB;\n    margin-top: 5px;\n}\n\n.section-option.selected .small-bar{\n    width: 70%;\n} \n\n.small-bar.selected {\n    width: 70%;\n}\n\n.section-option{\n    opacity: 0.8;\n}\n\n.section-option.selected{\n    opacity: 1;\n}\n\n.section-option.selected span {\n    font-size: calc(.20vw + 0.83em);\n}\n.section-option{\n    cursor: pointer; \n}\n\n.section-option:not(.selected):hover {\n    opacity: 0.9;\n    transform: scale(1.03);\n    transform-origin: left center;\n    transition: opacity 0.3s ease, transform 0.3s ease;\n  }\n  \n@media (max-width: 1500px) {\n    .nav-bar-section {\n        display: none;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
