import React, { useEffect } from 'react';
import './projectsPopup.css';
import { useTranslation } from 'react-i18next';

function ProjectsPopup(props) {
    const Projects = {
        ShoppingDead: 'shopping_dead',
        SteamTopia: 'steamtopia',
    }

    useEffect(() => {
        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = 'visible';
        };
    }, []);

    const { currentProject, setCurrentProject, closePopup } = props;

    const handleProjectChange = (project) => {
        setCurrentProject(project);
    };
    // {currentProject === Projects.ShoppingDead && <button onClick={() => handleProjectChange(Projects.SteamTopia)}>Steam</button>}
    // {currentProject === Projects.SteamTopia && <button onClick={() => handleProjectChange(Projects.ShoppingDead)}>Shopping</button>}


    const { t } = useTranslation();
    return (
        <div>
            <div className="overlay"></div>
            <div className="popup personal-background ">
                <div className="popup-grid">
                    <div className='popup-title'>
                        {currentProject === Projects.ShoppingDead && <span >Shopping Dead</span>}
                        {currentProject === Projects.SteamTopia && <span >SteamTopia</span>}
                    </div>
                    <div className='popup-close' onClick={() => closePopup()}>
                        <img src="assets/PersonalProj/Popup/xMark.png" alt="" />
                    </div>
                    <div className='popup-content-img'>
                        {currentProject === Projects.SteamTopia && <img src='assets/PersonalProj/SteamTopia.jpg' alt='' />}
                        {currentProject === Projects.ShoppingDead && <img src='assets/PersonalProj/ShoppingDead.png' alt='' />}

                    </div>
                    {currentProject === Projects.ShoppingDead && <div className='popup-content' dangerouslySetInnerHTML={{ __html: t('personalproj.projects.shoppingdead') }} />}
                    {currentProject === Projects.SteamTopia && <div className='popup-content' dangerouslySetInnerHTML={{ __html: t('personalproj.projects.steamtopia') }} />}
                    {currentProject === Projects.SteamTopia &&
                        <div className='popup-arrow arrow-left'>
                            <img src="assets/PersonalProj/Popup/arrow.png" alt="" onClick={() => handleProjectChange(Projects.ShoppingDead)} />
                        </div>
                    }
                    {currentProject === Projects.ShoppingDead &&
                        <>
                            <div />
                            <div className='popup-arrow arrow-right' >
                                <img src="assets/PersonalProj/Popup/arrow.png" alt="" onClick={() => handleProjectChange(Projects.SteamTopia)} />
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    );
}

export default ProjectsPopup;
