import React, { useRef, useLayoutEffect } from 'react'
import * as THREE from 'three'

function Triangle({ a, b, c}) {
   
    const vertices = [a,b,c];

    const meshRef = useRef();

    useLayoutEffect(() => {
        meshRef.current.geometry.setFromPoints(vertices.map((point) => new THREE.Vector3(...point)))
        meshRef.current.geometry.computeVertexNormals();
      }, );

    return (
    <>
      <mesh ref={meshRef}>
        <bufferGeometry />
        <meshStandardMaterial color={0xFF1493} side={THREE.DoubleSide} />

      </mesh>
      </>
    );
}

export default Triangle;