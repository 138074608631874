import {React, useEffect, useRef} from "react";
import "./mySkills.css";
import { useTranslation } from 'react-i18next';


function MySkills() {

  const { t } = useTranslation();

  const handleSelectorClick = (event) => {
    const selectors = document.querySelectorAll(".skills-selector");
    selectors.forEach((selector) => {
      selector.classList.remove("selected");
    });
    event.currentTarget.classList.add("selected");

    var skillDiv = event.currentTarget.getAttribute("data-skill");
    const skills = document.querySelectorAll(".skills-text-area");
    skills.forEach((skill) => {
      skill.hidden = true;
    });
    document.getElementById(skillDiv).hidden = false;
  };

  const skillsLeftRef = useRef(null);
  const skillsRightRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null, 
      rootMargin: '0px', 
      threshold: 0.25, 
    };

    const callback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.remove('paused-animation');
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);

    if (skillsLeftRef.current) {
      observer.observe(skillsLeftRef.current);
    }

    if (skillsRightRef.current) {
      observer.observe(skillsRightRef.current);
    }


    // Cleanup by disconnecting the observer when the component unmounts
    return () => {
      observer.disconnect();
    };
  }, []); // Empty dependency array ensures that this effect runs once



  return (
    <>
      <div className="skills-back">
        <div ref={skillsLeftRef} className="skills-sidebars-left paused-animation">
          <span>
            {t("skills.sidebars.learning")}</span>
      </div>
      <div ref={skillsRightRef} className="skills-sidebars-right paused-animation">
        <span>
          {t("skills.sidebars.improving")}</span>
      </div>
        </div>

      <div className="skills-area">
        <div className="skills-toparea">
          <div className="skills-selector selected" data-skill='netCoreSkill' onClick={handleSelectorClick}>
            <img
              className="skill-selectorimg"
              src="assets/Skills/netCore.png"
              alt=""
            />
          </div>
          <div className="skills-selector" data-skill='sqlSkill' onClick={handleSelectorClick}>
            <img
              className="skill-selectorimg"
              src="assets/Skills/sqlServer.png"
              alt=""
            />
          </div>
          <div className="skills-sectiontitle">
            <span>{t('skills.title')}</span>
          </div>
          <div className="skills-selector" data-skill='seleniumSkill' onClick={handleSelectorClick}>
            <img
              className="skill-selectorimg selenium"
              src="assets/Skills/selenium.png"
              alt=""
            />
          </div>
          <div className="skills-selector" data-skill='htmlSkill' onClick={handleSelectorClick}>
            <img
              className="skill-selectorimg html5 "
              src="assets/Skills/html5.png"
              alt=""
            />
          </div>
        </div>

        <div className="skills-bottomarea">
          <div id="netCoreSkill" className="skills-text-area" hidden={false}>
            <div className="skills-maintext">
              <span>
                {t('skills.netCore.mainFirstText')}
                <br />
                <br />
                {t('skills.netCore.mainSecondText')}
              </span>
            </div>
            <div className="skills-subtext">
              <span>
                {t('skills.netCore.skillsSubtext')}
              </span>
            </div>
          </div>

          <div id="sqlSkill" className="skills-text-area" hidden={true}>
            <div className="skills-maintext">
              <span>
                {t('skills.sqlServer.mainFirstText')}
                <br />
                <br />
                {t('skills.sqlServer.mainSecondText')}
              </span>
            </div>
            <div className="skills-subtext">
              <span>
                {t('skills.sqlServer.skillsSubtext')}
              </span>
            </div>
          </div>

          <div id='seleniumSkill' className="skills-text-area" hidden={true}>
            <div className="skills-maintext">
              <span>
                {t('skills.selenium.mainFirstText')}
                <br />
                <br />
              </span>
            </div>
            <div className="skills-subtext">
              <span>
                {t('skills.selenium.skillsSubtext')}
              </span>
            </div>
          </div>

          <div id='htmlSkill' className="skills-text-area" hidden={true}>
            <div className="skills-maintext">
              <span>
                {t('skills.html.mainFirstText')}
                <br />
                <br />
                {t('skills.html.mainSecondText')}
              </span>
            </div>
            <div className="skills-subtext">
              <span>
                {t('skills.html.skillsSubtext')}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MySkills;

