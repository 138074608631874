import React, { useState, useRef, useEffect, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import "../navBars.css";
import "./languagePopup.css";

function TopNavBar({ onHeightChange, onClickContacts}) {
  // State to manage the visibility of the popup menu
  var showMenu = false;
  const [position, setPosition] = useState({ x: 0, y: 0 });

  // Function to handle button click and display popup menu
  const handleButtonClick = (event) => {
    var buttonDiv = document.querySelector("#languageButton");
    const arrow = document.querySelector(".dropdown-button");

    const rect = buttonDiv.getBoundingClientRect();

    arrow.classList.toggle("up");

    setPosition({ x: rect.left, y: rect.top + rect.height });
    if (!showMenu) {
      showMenu = true;
      const dropdown = document.querySelector("#popupMenu");
      dropdown.classList.toggle("active");
    } else {
      showMenu = false;
      const dropdown = document.querySelector("#popupMenu");
      dropdown.classList.toggle("active");
    }
  };

  // Function to close popup menu when clicking outside
  const handleCloseMenu = (event) => {
    var buttonDiv = document.querySelector("#languageButton");
    const arrow = document.querySelector(".dropdown-button");
    const dropdown = document.querySelector("#popupMenu");

    if (!buttonDiv.contains(event.target)) {
      if (showMenu) {
        arrow.classList.toggle("up");
        dropdown.classList.toggle("active");
        showMenu = false;
      }
    }
  };

  // Event listener to handle click outside popup menu
  document.addEventListener("click", handleCloseMenu);

  const navBarBackgroud = useRef(null);
  const [childHeight, setChildHeight] = useState(null);

  useEffect(() => {
    const updateHeight = () => {
      if (navBarBackgroud.current) {
        const height = navBarBackgroud.current.offsetHeight;
        if (height !== childHeight) {
          setChildHeight(height);
          onHeightChange(height); // Trigger the event when height changes
        }
      }
    };

    // Initial height update
    updateHeight();

    // Attach a resize listener to check for changes in height
    window.addEventListener('resize', updateHeight);

    window.addEventListener('load', function () {
      updateHeight();
    })

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateHeight);
    };

  }, [childHeight, onHeightChange]);

  const { i18n, t } = useTranslation();
  const [language, setLanguage] = useState('english');

  const changeLanguage = useCallback((language) => {
    i18n.changeLanguage(language);
    if (language === 'pt') {
      document.getElementById('pt-lang').hidden = true;
      document.getElementById('en-lang').hidden = false;
      setLanguage('português');
      localStorage.setItem('language', 'pt');
    } else {
      document.getElementById('pt-lang').hidden = false;
      document.getElementById('en-lang').hidden = true;
      setLanguage('english');
      localStorage.setItem('language', 'en');
    }
  }, [i18n, setLanguage]);

  useEffect(() => {
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      changeLanguage(storedLanguage);
    }
  }, [i18n, changeLanguage]);



  return (
    <>
      <img ref={navBarBackgroud}
        className="nav-bar-background"
        src="assets/NavTopBlue2.png"
        alt="top nav bar"
      />
      <div className="menu-container">
        <div className="menu-content shadow-blue">
          <div className="">
            <p href="#home">joaopacheco.com</p>
          </div>
          <div className=""></div>
          <div className="menu-item language-menu">
            <button className="menu-button popupButton" onClick={() =>onClickContacts(true)}>{t('contacts.navBar')}</button>
          </div>
          <div id="languageButton" className="menu-item">
            <button
              id="popupButton"
              className="menu-button popupButton"
              onClick={handleButtonClick}
            >
              {language}
              <img
                className="dropdown-button"
                src="assets/DropdownBtn.png"
                alt=""
              />
            </button>
          </div>
          {
            <div
              id="popupMenu"
              className="popup-menu"
              style={{ left: `${position.x}px`, top: `${position.y}px` }}
            >
              <ul>
                <li id='pt-lang' onClick={() => changeLanguage('pt')} hidden={false}>
                  <span className="language-content">português</span>
                  <div className="language-background" />
                </li>
                <li id='en-lang' onClick={() => changeLanguage('en')} hidden={true}>
                  <span className="language-content">english</span>
                  <div className="language-background" />
                </li>
              </ul>
            </div>
          }
        </div>
      </div>
    </>
  );
}

export default TopNavBar;
