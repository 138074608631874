import {React, useState} from "react";
import './iteractiveSentence.css';

function Letter({ letter, cssName,  initialColor, hovercolor, increaseOnHover }) {
  const [color, setColor] = useState(initialColor);
  

  return (
    <span 
      className={`${cssName} interactive-letter ${(increaseOnHover && letter.trim() !== "") ? 'letter-expand' : ''}`}
      style={{ color}}
      onMouseEnter={() => 
        {
          setColor(hovercolor);
      }}
      onMouseLeave={() => {
        setColor(initialColor);
      }}
    >
      {letter}
    </span>
  );
}

function Sentence({ sentence, cssName, initialColor, hoverColor, fontSize, increaseOnHover }) {
  return (
    <div className={cssName+"-container"}
    style={{ fontSize: fontSize }}
    >
      {sentence.split("").map((letter, index) => (
        <Letter key={index} increaseOnHover={increaseOnHover}  letter={letter} initialColor={initialColor} hovercolor={hoverColor} cssName={cssName}/>
      ))}
    </div>
  );
}
export default Sentence;
