// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rectangleSection {
    position: relative;
    margin-top: 30vh;
    margin-left: 10vw;
    width: 57.3vw;
    min-height: 100px;
    background-color: #2C2E33;

}

.title {
    position: absolute;
    font-size: calc(15px + 4vmin);
    margin-left: 20px;
    top: 5px;
    z-index: 1;
    color: #2EE9FF;
}


.text {
    position: absolute;
    ;
    font-size: calc(6px + 2vmin);
    margin-left: 20px;
    top: 80px;
    z-index: 1;
    color: #2EE9FF;
    ;
}

div.container {
    display: flex;
    height: 100%;
}

div.middle-right,
div.middle-left {
    flex: 1 1;
}

div.middle-main {
    width: 1000px;
    flex-direction: column;
    padding-top: 100px;
}

.section {
    margin-bottom: 200px;
    width: 100%;
}

.section-title {
    color: #FFC0CB;
    font-size: 32px;
    font-weight: bold;
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
}`, "",{"version":3,"sources":["webpack://./src/components/Section/section.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;IACb,iBAAiB;IACjB,yBAAyB;;AAE7B;;AAEA;IACI,kBAAkB;IAClB,6BAA6B;IAC7B,iBAAiB;IACjB,QAAQ;IACR,UAAU;IACV,cAAc;AAClB;;;AAGA;IACI,kBAAkB;;IAElB,4BAA4B;IAC5B,iBAAiB;IACjB,SAAS;IACT,UAAU;IACV,cAAc;;AAElB;;AAEA;IACI,aAAa;IACb,YAAY;AAChB;;AAEA;;IAEI,SAAO;AACX;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,oBAAoB;IACpB,WAAW;AACf;;AAEA;IACI,cAAc;IACd,eAAe;IACf,iBAAiB;IACjB,gCAAgC;IAChC,kBAAkB;IAClB,SAAS;IACT,QAAQ;AACZ","sourcesContent":[".rectangleSection {\n    position: relative;\n    margin-top: 30vh;\n    margin-left: 10vw;\n    width: 57.3vw;\n    min-height: 100px;\n    background-color: #2C2E33;\n\n}\n\n.title {\n    position: absolute;\n    font-size: calc(15px + 4vmin);\n    margin-left: 20px;\n    top: 5px;\n    z-index: 1;\n    color: #2EE9FF;\n}\n\n\n.text {\n    position: absolute;\n    ;\n    font-size: calc(6px + 2vmin);\n    margin-left: 20px;\n    top: 80px;\n    z-index: 1;\n    color: #2EE9FF;\n    ;\n}\n\ndiv.container {\n    display: flex;\n    height: 100%;\n}\n\ndiv.middle-right,\ndiv.middle-left {\n    flex: 1;\n}\n\ndiv.middle-main {\n    width: 1000px;\n    flex-direction: column;\n    padding-top: 100px;\n}\n\n.section {\n    margin-bottom: 200px;\n    width: 100%;\n}\n\n.section-title {\n    color: #FFC0CB;\n    font-size: 32px;\n    font-weight: bold;\n    transform: translate(-50%, -50%);\n    position: absolute;\n    left: 50%;\n    top: 50%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
