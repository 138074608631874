import React from "react";
import "./aboutMeSection.css";
import { useTranslation } from 'react-i18next';


function AboutMeSection() {
  // const [firstImageHeight, setFirstImageHeight] = useState(0);

  // const handleImageLoad = () => {
  //   // Get the computed height of the first image
  //   const img1 = document.getElementById('photoBackgroud');
  //   if (img1) {
  //     const height = img1.clientHeight;
  //     setFirstImageHeight(height);
  //   }
  // };

  const { t } = useTranslation();

  return (
    <div>
      <div className="aboutMeContainer">
        <div className="photoContainer">
          <img id="photoBackgroud" className="photoBackgroud" src="assets/AboutMe/PhotoBackground.png"
            //   onLoad={handleImageLoad} 
            alt="" />
          <div className="outline-div">
            <svg style={{ overflow: 'visible' }} width="250" height="343" viewBox="0 0 238 327" fill="none" xmlns="http://www.w3.org/2000/svg">
              <clipPath id="clipPathDown" clipPathUnits="objectBoundingBox">
                <polygon points="0.6,0.6 1.1,0.6 1.1,1.1 0.6,1.1" />
              </clipPath>
              <path clipPath="url(#clipPathDown)" d="M20.8 322L110.772 322C137.268 322 150.516 322 161.45 319.375C196.186 311.036 223.308 283.914 231.648 249.177C234.272 238.244 234.272 224.996 234.272 198.5L234.272 20.8C234.272 16.3375 234.272 14.1063 233.83 12.2649C232.426 6.41449 227.858 1.84663 222.008 0.442081C220.166 -5.93413e-7 217.935 -5.97064e-7 213.472 -6.04366e-7L45.5 -8.79222e-7C35.7383 -8.95195e-7 30.8575 -9.03182e-7 26.8294 0.967052C14.0317 4.03951 4.03951 14.0317 0.967053 26.8294C6.53589e-7 30.8575 7.5697e-7 35.7383 9.63731e-7 45.5L0.00000637969 301.2C0.00000647421 305.662 0.00000652147 307.894 0.442088 309.735C1.84664 315.586 6.4145 320.153 12.2649 321.558C14.1063 322 16.3375 322 20.8 322Z" fill="white" />
            </svg>
          </div>
          <div className="outline-div upper">
            <svg style={{ overflow: 'visible' }} width="250" height="343" viewBox="0 0 238 327" fill="none" xmlns="http://www.w3.org/2000/svg">
              <clipPath id="clipPathUpper" clipPathUnits="objectBoundingBox">
                <polygon points="-0.1, -0.1 0.4, -0.1 0.4, 0.4 -0.1, 0.4" />
              </clipPath>
              <path clipPath="url(#clipPathUpper)" d="M20.8 322L110.772 322C137.268 322 150.516 322 161.45 319.375C196.186 311.036 223.308 283.914 231.648 249.177C234.272 238.244 234.272 224.996 234.272 198.5L234.272 20.8C234.272 16.3375 234.272 14.1063 233.83 12.2649C232.426 6.41449 227.858 1.84663 222.008 0.442081C220.166 -5.93413e-7 217.935 -5.97064e-7 213.472 -6.04366e-7L45.5 -8.79222e-7C35.7383 -8.95195e-7 30.8575 -9.03182e-7 26.8294 0.967052C14.0317 4.03951 4.03951 14.0317 0.967053 26.8294C6.53589e-7 30.8575 7.5697e-7 35.7383 9.63731e-7 45.5L0.00000637969 301.2C0.00000647421 305.662 0.00000652147 307.894 0.442088 309.735C1.84664 315.586 6.4145 320.153 12.2649 321.558C14.1063 322 16.3375 322 20.8 322Z" fill="white" />
            </svg>

            <div className="outline-div photo">
              <img className="my-photo" src="assets/AboutMe/MyPhoto.png" alt="" />
            </div>
          </div>

        </div>

        <div className="textContainer">
          <div className="textTitle">
            <img src="assets/AboutMe/TextTitle.png" alt="" />
            <span className="section-title">{t('aboutme.title')}</span>
          </div>
          <div className="textContent">
            <img src="assets/AboutMe/TextContent.png" alt=""
            // style={{ height: `${firstImageHeight}px`}}
            />
            <div className="textArea">
              <span className="aboutme-text">
                {t('aboutme.intro')}
                <br /><br />
                {t('aboutme.childhood')}
                <br /><br />
                {t('aboutme.journey')}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutMeSection;
