import React from 'react';
import "./footer.css";
import { useTranslation } from 'react-i18next';

function Footer() {

  const { t } = useTranslation();

  return (
    <footer className='footer'>
      <div className='footer-back'>
        <img className='footer-img' src="assets/Footer/footer.png" alt="map" />
      </div>
      <div className='footer-parent'>
        <div className='footer-links'>
          <a href="https://www.linkedin.com/in/jo%C3%A3o-pacheco-me/" name="LinkedIn" rel="noreferrer" target="_blank">linkedIn</a>
        </div>
        <div className='footer-content'>
          <span>{t('footer.website')}</span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
