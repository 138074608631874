import React, { useRef , useState} from 'react'
import Line from './line.jsx'

import { Canvas, useFrame, useThree } from "react-three-fiber";
import { Vector3} from 'three';
import './background.css'
import Triangle from './triangle.jsx';
import { useDrag } from '@use-gesture/react'

function Isocahedron() {

    const meshRef = useRef();
    const meshRef1 = useRef();
    const groupRef = useRef();
    const megaGroupRef = useRef();
    const {viewport} = useThree();
    const [isDragging, setIsDragging] = useState(false);

    useFrame((state) => {
        if (!isDragging) {
            groupRef.current.rotation.x = groupRef.current.rotation.y += 0.0025;
            groupRef.current.rotation.z = groupRef.current.rotation.x += 0.0025;
        }
    });

    const bind = useDrag(({ offset: [x, y] }) => {
        setIsDragging(true);
        // groupRef.current.rotation.x += x * 0.00005;
        // groupRef.current.rotation.y += y * 0.00005;
        setIsDragging(false);
    },{ onRelease: () => setIsDragging(false) }
    );
    const radius = 1.15;
    const calculatePositions = () => {
        const pointNorth = new Vector3(radius * Math.cos(Math.PI/2),radius * Math.cos(Math.PI/2),radius * Math.sin(Math.PI/2));
        const pointSouth = new Vector3(radius * Math.cos(-Math.PI/2),radius * Math.cos(-Math.PI/2),radius * Math.sin(-Math.PI/2));
        const positions = [];
        positions.push(pointNorth);
        positions.push(pointSouth);
        var lat = 26.57;
        var long = 0;

        for (var i = 0; i < 10; i++) {
            var latRad = lat * Math.PI /180;
            var longRad = long * Math.PI /180;

            var x = radius * Math.cos(latRad) * Math.cos(longRad);
            var y = radius * Math.cos(latRad) * Math.sin(longRad);
            var z = radius * Math.sin(latRad);
            const point = new Vector3(x,y,z);
            positions.push(point);
            lat = -lat;
            long += 36;
        }
        return positions;
    };

    const positions = calculatePositions();

    const makeLines = () => {
        const lines = [];
        for (let i = 2; i < 11; i++) {
         lines.push(<Line start={positions[i]} end={positions[i+1]}  key={i} />);

        }
        for (let i = 2; i < 10; i++) {
            lines.push(<Line start={positions[i]} end={positions[i+2]}  key={20+i} />);
        }
        for (let i = 2; i < 12; i++) {
            if(i%2===0){
             lines.push(<Line start={positions[0]} end={positions[i]} key={40+i} />);
            }else{
                lines.push(<Line start={positions[1]} end={positions[i]} key={40+i} />);
            }
        }
        lines.push(<Line start={positions[3]} end={positions[11]}  key={102} />);
        lines.push(<Line start={positions[2]} end={positions[10]}  key={101} />);
        lines.push(<Line start={positions[11]} end={positions[2]} key={100}/>);
        return <>{lines}</>
        
    }

    const triangles = () => {
        return(
            <>
        
            <Triangle a={positions[0]} b={positions[2]} c={positions[4]}/>
            <Triangle a={positions[1]} b={positions[3]} c={positions[5]}/>
            <Triangle a={positions[6]} b={positions[7]} c={positions[8]}/>
            <Triangle a={positions[9]} b={positions[10]} c={positions[11]}/>
            </>
            
        );
   
    }

    return (
        <group {...bind()}  ref={megaGroupRef}  position={[viewport.width/4+viewport.width/12,viewport.height/3,0]}>
        <pointLight intensity={25} position={[-viewport.width/4,0,1]} args={[0xFFFFF]} /> 
        <ambientLight intensity={0.7}/>
        <group ref={groupRef} scale={[1.2,1.2,1.2]}  dispose={null}>
        <mesh ref={meshRef}  position={[0, 0, 0]} scale={[1.5, 1.5, 1.5]}>
            <icosahedronGeometry args={[0.35,0]} />
            <meshStandardMaterial color={0x05A0B3} wireframe={false} />
        </mesh>
        <mesh  ref={meshRef1} position={[0, 0, 0]} scale={[1.5, 1.5, 1.5]}>
                <icosahedronGeometry args={[0.35, 0]} />
                <meshStandardMaterial color={0x2EE9FF} wireframe={true} />
        </mesh> 
        {
            /* positions.map((position,index) => {
                return(
                    <mesh  key={index} position={[position.x,position.y,position.z]}>
                        <sphereGeometry args={[0.06]} />
                        <meshBasicMaterial color={0xFFFFFF}  />
                    </mesh>
                )
            }) */
        } 
        {makeLines()}
        {triangles()}
        </group>
        </group>
    );

  }


function Background({ className, isocahedron }) {

    return (
      <>
        <Canvas  className={className}>
          <ambientLight intensity={0.5} />
          {isocahedron && <Isocahedron />}
        </Canvas>
      </>
    );
}



export default Background;
