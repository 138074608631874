import React from 'react';
import "./letsTalk.css";
import { useTranslation } from 'react-i18next';
import ContactsPopup from './ContactsPopup/contactsPopup';

function LetsTalk({onClickContacts, contactsPopupOpen}) {

    const { t } = useTranslation();

    const closePopup = () => {
        onClickContacts(false);
    }

    return (
        <>
            <div className="lets-section">
                <div className='letstalk-background'>
                    <img src="assets/Contacts/background.png" alt="map" />
                </div>
                <div className="lets-content">
                    <span className='content-subtitle'>{t('contacts.content.subtitle')}</span>
                    <span className='content-title' > {t('contacts.content.title')}</span>
                    <button className='content-button' onClick={() => onClickContacts(true)}> {t('contacts.content.email')}  </button>
                </div>
            </div>
            {contactsPopupOpen &&
                <ContactsPopup closePopup={closePopup} />
            }
        </>
    );
};

export default LetsTalk;
