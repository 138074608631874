// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainText{
    pointer-events: auto;
    letter-spacing: -1px;
}

.secondaryText{
    font-weight: bold;
    letter-spacing: 1px;
}


.secondaryText-container{
    text-align: left;
}

.mainText-container {
    pointer-events: none;
    transform-origin: left bottom;
    text-align: left;
    transform: scale(1);
}
  
.letter-expand:hover {
    transform: scale(1.05);
    transform-origin: bottom left;
    transition: transform 0.1s ease-in-out;
    display: inline-block; 
  }
  `, "",{"version":3,"sources":["webpack://./src/components/iteractiveSentence.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,oBAAoB;AACxB;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;AACvB;;;AAGA;IACI,gBAAgB;AACpB;;AAEA;IACI,oBAAoB;IACpB,6BAA6B;IAC7B,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;IACtB,6BAA6B;IAC7B,sCAAsC;IACtC,qBAAqB;EACvB","sourcesContent":[".mainText{\n    pointer-events: auto;\n    letter-spacing: -1px;\n}\n\n.secondaryText{\n    font-weight: bold;\n    letter-spacing: 1px;\n}\n\n\n.secondaryText-container{\n    text-align: left;\n}\n\n.mainText-container {\n    pointer-events: none;\n    transform-origin: left bottom;\n    text-align: left;\n    transform: scale(1);\n}\n  \n.letter-expand:hover {\n    transform: scale(1.05);\n    transform-origin: bottom left;\n    transition: transform 0.1s ease-in-out;\n    display: inline-block; \n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
