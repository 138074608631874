import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './contactsPopup.css';
import '../../PersonalProjects/ProjectsPopup/projectsPopup.css';

function ContactsPopup(props) {

    useEffect(() => {
        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = 'visible';
        };
    }, []);

    const { t } = useTranslation();

    const { closePopup } = props;

    var email = "joao.pacheco.me@gmail.com";

    return (
        <div id="letstalk-popup">
            <div className="overlay"></div>
            <div className="popup contacts-background">
                <div className='popup-content-area'>
                    <form action="https://formspree.io/f/meqyvdja" method="POST">
                        <div className='popup-title-section'>
                            <span className='popup-title-contacts'>{t('contacts.popup.title')}</span>
                            <div className='popup-close-contacts' onClick={() => closePopup()}>
                                <img src="assets/PersonalProj/Popup/xMark.png" alt="" />
                            </div>
                        </div>
                        <div className='popup-content-contacts'>
                            <div className='contacts-content'>
                                <input type="hidden" name="_next" value="http://web.joaopacheco.net/thank-you" />
                                <input type="hidden" name="_subject" value="New submission!" />
                                <input type="hidden" name="_template" value="basic" />
                                <span className='contacts-header'>{t('contacts.popup.name')}</span>
                                <input name='name' required className='contacts-inputbox' type="text" id="myInput" style={{ width: "300px" }} maxLength={35} placeholder={t('contacts.popup.nameplaceholder')} />
                                <span className='contacts-header'>{t('contacts.popup.email')}</span>
                                <input name='email' required className='contacts-inputbox' type="email" id="myInput" style={{ width: "325px" }} maxLength={50} placeholder={t('contacts.popup.emailplaceholder')} />
                                <span className='contacts-header'>{t('contacts.popup.message')}</span>
                                <textarea name='message' required className='contacts-inputbox textarea' type="text" id="myInput" style={{ width: "95%" }} maxLength={1000} placeholder={t('contacts.popup.messageplaceholder')} />
                            </div>
                            <div className='contacts-send'>
                                <button type="submit" className='contacts-button-send'>{t('contacts.popup.send')} </button>
                            </div>
                        </div>
                        <div className='popup-content-email'>
                            <span className='popup-email-content popup-or'>{t('contacts.popup.or')}</span>
                            <div>
                                <span className='popup-email-content popup-emailto'>{t('contacts.popup.emailto')}</span>
                                <span className='popup-email-content popup-email'>{email}</span>
                            </div>
                        </div>

                    </form>
                </div>
            </div>

        </div>
    );
}

export default ContactsPopup;