// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.background {
  position: fixed !important;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

}

.background-fixed {
  position: absolute !important;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

`, "",{"version":3,"sources":["webpack://./src/components/background.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,WAAW;EACX,WAAW;EACX,YAAY;EACZ,MAAM;EACN,OAAO;;AAET;;AAEA;EACE,6BAA6B;EAC7B,WAAW;EACX,WAAW;EACX,YAAY;EACZ,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;AACX","sourcesContent":[".background {\n  position: fixed !important;\n  z-index: -1;\n  width: 100%;\n  height: 100%;\n  top: 0;\n  left: 0;\n\n}\n\n.background-fixed {\n  position: absolute !important;\n  z-index: -1;\n  width: 100%;\n  height: 100%;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
